import { getAuthState } from '@sparky/framework';

import { AddOnInfo } from '../data/queryResultsDefinition';
import { Addon_licenses } from './AppAccelContext';

import { appAccelAppID } from './constants';
import Logger from './Logger';

import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';
import { LicenseInfoBase } from '../data/sparkyDefs';
import { rnOnlyLicenseTypes } from './constants';

const DEPRACATED_TSG_IDS = ['1320447254','1807272674'];

const LicenseSingleton = (() => {
    let naaLicense: Addon_licenses | undefined = undefined;
    return {
        getInstance: (): Addon_licenses => {
            if (naaLicense === undefined) {
                naaLicense = {items: [], includeAdem: false, in_grace_period: false, urlEnvModifier: ''}
            }

            return naaLicense;
        }
    }
})()

export const getAACLicenseArray = (): Addon_licenses => {
    return LicenseSingleton.getInstance()
}

function checkIfTSGisDeprecated(deprecatedIds: string[] = [], tsgId: string = '') {
    return deprecatedIds.some((id) => tsgId === id);
}

export function sparkyHasLicense() {
    let in_grace_period = true;
    let includeAdem = false;
    let validLicense = false;

    // licInfo is local storage for license info.  We don't have context yet so we save it locally.  When we make
    // the capabilities call in AppAccelRoutes (only done once), we get licInfo and store it in context.
    const licInfo = LicenseSingleton.getInstance();
    const authState = getAuthState();

    const { tsg_id: tsgId} = authState;

    const tsgIsDeprecated = checkIfTSGisDeprecated(DEPRACATED_TSG_IDS, tsgId);
    if (tsgIsDeprecated) {
        return true; // for deprecated TSG ids, we consider them to have a valid license always and APIs will handle the licensing
    }

    const instances:  Readonly<TsgInstances> = authState?.instances
    if (instances?.length > 0) {
        for (let inst=0, instLen=instances.length; inst < instLen; inst++) {
            const appId = instances[inst]?.app_id;
            // only check license for prisma access app_ids
            if (appId !== 'prisma_access_edition' && appId !== 'prisma_access_edition_onprem' && 
                appId !== 'prisma_access_panorama' ) { continue; }

            const entitlements: LicenseInfoBase[] = instances?.get(appId)?.entitlements;

            if (entitlements) {
                licInfo.items.length = 0;
                const items: AddOnInfo[] = [];

                for (let i=0, len=entitlements.length; i < len; i++) {
                    const lic = entitlements[i];
                    Logger.info(`license from sparky '${lic.app_id}'`);
                    console.info(`license from sparky '${lic.app_id}'`);
                    if (lic.app_id === appAccelAppID) {
                        // TODO below setter is causing an infinite loop, so we need to find another way to set the license state
                        // actions.setValues({ pa_app_accel_license: true });
                        // console.info(`setting values for PA App Accel licensne in sparky - pa_app_accel_license '${lic.app_id}'`);
                        validLicense = true;
                        const expiration = lic.license_expiration as string;
                        const in_grace = (new Date().getTime()) > (new Date(expiration).getTime());
                        const item: AddOnInfo = {license_type: lic.license_type, license_expiration: expiration, in_grace_period: in_grace}
                        items.push(item)

                        const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return lic.license_type === licType })
                        includeAdem = includeAdem || foundRn === -1;  
                        in_grace_period = in_grace_period && !!(item?.in_grace_period);
                    }

                    licInfo.items = items;
                    licInfo.includeAdem = includeAdem
                    licInfo.in_grace_period = in_grace_period;
                }
                
                if (licInfo.items.length > 0) {
                    Logger.info(`found App_Accel license, ${appId}`);
                    validLicense = true;
                    break;
                }
            }
            if (licInfo.items.length > 0) {
                break;
            }
        }
    } else {
        validLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }
    Logger.info(`APP_ACCEL has license = ${validLicense}`);
    console.info('SDWAN license found, APP_ACCEL has license ? - ', validLicense);
    return validLicense;
}
